import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import xhr from "../client/xhr";
import { addToastError } from "../commons/components/toast/toasts";
import { concatMap, from, map, toArray } from "rxjs";
import masterdataService from "../masterdata/MasterdataService";
import { useTranslation } from "react-i18next";

const ForecastSummary = () => {

    const { i18n } = useTranslation();
    const navigate = useNavigate();

    const [data, setData] = useState<IPartner[]>([]);

    const getGraphQLSummaryForAccount = () => {
        return "{ partners { consignees { accountId partnerId created party_name_1 } } }";
    }

    interface IPartner {
        consigneeName: string;
        name: string;
        zip: string;
        town: string;
        street: string;
        partnerId: string;
        latestUpdate: string;
    }

    useEffect(() => {
        const params = { "query": getGraphQLSummaryForAccount() };
        xhr.post('/gateway/automotive/graphql', params)
            .then(response => {
                if (response.data?.data?.partners) {
                    const partners = response.data.data.partners;
                    from(partners.consignees)
                        .pipe(
                            concatMap((consignee: any) => {
                                return from(masterdataService.findPartnerDetailByPartnerId(consignee.partnerId))
                                    .pipe(
                                        concatMap((partnerDetail: any) => {
                                            return from(masterdataService.findAddresses([partnerDetail.primaryGln], ''))
                                                .pipe(
                                                    map((address: any) => {
                                                        return { consigneeName: consignee.party_name_1, name: address[0].name, partnerId: consignee.partnerId, street: address[0].street, zip: address[0].zip, town: address[0].town, latestUpdate: consignee.created } as IPartner;
                                                    })
                                                )
                                        })
                                    );
                            }),
                            toArray()
                        )
                        .subscribe({
                            next: (list => {
                                setData(list);
                            }),
                            complete: () => {
                                console.log("done")
                            },
                            error: (err) => {
                                console.error(err);
                                setData([]);
                            }
                        })
                } else {
                    addToastError("Unexpected response");
                }
            }).catch(e => {
                console.log(e);
                addToastError("Error occurs");
            })
    }, []);

    return <>
        <div className={"mt-5"}>
            <h5>{i18n.t("Forecast.Summary.Title")}</h5>
            <div className="row">
                <div className="col">
                    <table className="table table-bordered bg-white">
                        <thead>
                            <tr className={"text-center align-top"}>
                                <th>{i18n.t("Forecast.Summary.Header.Buyer")}</th>
                                <th>{i18n.t("Forecast.Summary.Header.Today")}</th>
                                <th>{i18n.t("Forecast.Summary.Header.Tomorrow")}</th>
                                <th>{i18n.t("Forecast.Summary.Header.ThisWeek")}</th>
                                <th>{i18n.t("Forecast.Summary.Header.NextWeek")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map(d => {
                                return <tr key={"row" + d.partnerId} style={{ cursor: "pointer" }} onClick={() => navigate(`/forecast/${d.partnerId}`)}>
                                    <td key={"col" + d.partnerId}>
                                        <table>
                                            <tbody>
                                                <tr><td>{d.name} - {d.consigneeName}</td></tr>
                                                <tr><td>{d.zip} {d.town} {d.street}</td></tr>
                                                <tr><td>{i18n.t("Forecast.Summary.Body.UpdateDate")}: {new Date(d.latestUpdate).toLocaleDateString()}</td></tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td>Today data</td>
                                    <td>Tomorrow data</td>
                                    <td>This week data</td>
                                    <td>Next week data</td>
                                </tr>;
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </>
}

export default ForecastSummary;