import xhr from "../client/xhr";
import { Vat } from "../product/Domain";
import {Variable, PatchVariables, IPartnerDetail} from "./Domain";
import {IInvoicingAccountInfoData} from "../company/CompanyPage";
import { IEditelAddress } from "../account/Domain";

class MasterdataService {

    private static instance: MasterdataService;

	public static getInstance(): MasterdataService {
        if (!MasterdataService.instance) {
            MasterdataService.instance = new MasterdataService();
        }
        return MasterdataService.instance;
    }

	public retrieveCurrencies() : Promise<string[]> {
        return xhr.get('/gateway/masterdata/config/currencies')
            .then((response : any) => response.data);
    }

    public retrieveAdvancedSettingsVariables() : Promise<Variable[]> {
         return xhr.get('/gateway/masterdata/variables').then((response : any) => Object.values(response.data).map(entry => new Variable(entry)));
    }

    public updateAdvancedSettingsVariables(patchData: PatchVariables) : Promise<Variable[]> {
        return xhr.patch('/gateway/masterdata/variables', patchData)
            .then((response : any) => Object.values(response.data).map(entry => new Variable(entry)));
    }

    public retrieveInvoicingAccountInfo(accountId: string, organization : string, tenantId : string) : Promise<IInvoicingAccountInfoData> {
        return xhr.get(`/gateway/masterdata/organization/${organization}/tenant/${tenantId}/accounts/${accountId}/invoicing-account`).then((response : any) => response.data as IInvoicingAccountInfoData);
    }

    public saveInvoicingAccountInfo(accountId: string, organization : string, tenantId : string,data: IInvoicingAccountInfoData) : Promise<IInvoicingAccountInfoData> {
        return xhr.put(`/gateway/masterdata/organization/${organization}/tenant/${tenantId}/accounts/${accountId}/invoicing-account`, data)
            .then((response : any) => response.data as IInvoicingAccountInfoData);
    }

    public retrieveVats() : Promise<Vat[]> {
        return xhr.get('/gateway/masterdata/config/vats')
            .then((response : any) => response.data);
    }

    public findAddresses(glns: string[], query: string) : Promise<IEditelAddress[]> {
        return xhr.post(`/gateway/masterdata/addresses`, {query: query, glns: glns})
            .then((response : any) => response.data);
    }

    public findPartnerDetailByPartnerId(partnerId: string) : Promise<IPartnerDetail[]> {
        return xhr.get(`/gateway/masterdata/partners/${partnerId}`)
            .then((response : any) => response.data);
    }
}

const masterdataService = MasterdataService.getInstance();
export default masterdataService;
