import React, {useEffect, useState} from "react";
import './forecast.css';
import {addDays, format, parse} from "date-fns";
import lodash from "lodash";
import xhr from "../client/xhr";
import {useParams} from "react-router-dom";
import {addToastError} from "../commons/components/toast/toasts";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";

const WEEK_DATE_PATTERN = "RRRRII";
const DATE_PATTERN = "yyyy-MM-dd";
const SHORT_DATE_PATTERN = "dd.MM";

interface IForecast {
    quantity: number;
    quantity_unit: string;
    delivery_start_date: string;
    delivery_end_date: string;
    created: string;
    commitment_delivery_code: string;
    frequency: string;
    id: string;
}

interface ICumulatives {
    quantity_received: number,
    quantity_ordered: number,
    quantity_shipped: number
}

interface IItem {
    article_number_buyer: string;
    article_number?: string;
    article_ean?: string;
    created: string;
    cumulatives: ICumulatives,
    id: string;
    kanban_number: string;
    specification: string;
    engineering_change: string;
    order_number: string;
    calloff_number: string;
    forecasts?: IForecast[];
}

export interface IConsignee {
    party_name_1: string;
    party_id: string;
    contact?: string;
    created: string;
    party_qualifier?: string;
    id: string;
    street_1?: string;
    final_destination?: string;
    transhipment_place?: string;
    place?: string;
    postcode?: string;
    country_code?: string;
    dock_code: string;
    warehouse_code: string;
    items: IItem[];
}

type Params = {
    partnerId: string;
};

const getGraphQLForAccount = (partnerId: string, consigneeName?: string) => {
    let filterString = ` partnerId:"${partnerId}" `;
    if (consigneeName) {
        filterString += ` consigneeName:"${consigneeName}" `;
    }
    return "{ allforecasts(" + filterString + ") { consignees { id party_name_1 accountId partnerId created dock_code warehouse_code final_destination contact party_id items { id article_number_buyer article_number article_ean created kanban_number specification engineering_change order_number calloff_number cumulatives { quantity_received } forecasts { quantity quantity_unit delivery_start_date delivery_end_date frequency commitment_delivery_code delivery_start_date_original created}} } } }"
}

const Forecast = () => {

    const [data, setData] = useState<{ "consignees": IConsignee[] }>();
    const {partnerId} = useParams<Params>();
    const { i18n } = useTranslation();

    useEffect(() => {
        if (partnerId) {
            const params = {"query": getGraphQLForAccount(partnerId)};

            xhr.post('/gateway/automotive/graphql', params)
                .then(response => {
                    if (response.data?.data?.allforecasts) {
                        setData(response.data.data.allforecasts as { "consignees": IConsignee[] });
                    } else {
                        addToastError("Unexpected response");
                    }
                }).catch(e => {
                console.log(e);
                addToastError("Error occurs");
            })
        }
    }, [partnerId]);

    const getFirstDayOfWeek = (date: string): Date => {
        const weekNumber = date.slice(-2);
        const year = date.slice(0, 4);
        return parse(weekNumber.toString(), "I", new Date().setFullYear(+year));
    }

    const getWeekDays = (dateStr: string): Date[] => {
        const result = [];
        let date = getFirstDayOfWeek(dateStr);
        while (result.length < 7) {
            result.push(date);
            date = addDays(date, 1)
        }
        return result;

    }

    const createWeeksNowPlus3Months = () => {
        let weeks: string[] = [];
        let now = new Date();
        let endDate = new Date(now);
        endDate.setMonth(endDate.getMonth() + 3);
        let currentDate = now;
        while ( currentDate <= endDate) {
            let formattedDate = format(currentDate, WEEK_DATE_PATTERN,{weekStartsOn:1, firstWeekContainsDate: 4});
            weeks.push(formattedDate);
            currentDate.setDate(currentDate.getDate() + 1);
        }
        weeks = lodash.uniq([...weeks]);
        return weeks;
    }

    const weeks: string[] = createWeeksNowPlus3Months();
    const todayShort = format(new Date(),SHORT_DATE_PATTERN);

    if (!data) {
        return <div className={"mt-5 "}><h6>{i18n.t("Forecast.NoData")}</h6></div>;
    }

    return <div className={"forecast mt-5 "}>
        {data?.consignees.map((consignee,consigneeIndex) => {
            return <div key={"forecast-"+consigneeIndex}>
                <h5>{consignee.party_name_1}</h5>
                <Row key={"consignee" + consignee.id+"-row"+consigneeIndex}>
                    <Col sm={2} key={"consignee" + consignee.id+"-"+consigneeIndex+"-col1"}>
                        <Row key={"consignee" + consignee.id+"-"+consigneeIndex+"-col1-row1"}>
                            <Col sm={6} key={"consignee" + consignee.id+"-"+consigneeIndex+"-col1-row1-col1"}><h6>{i18n.t("Forecast.Header.Identifier")}:</h6></Col>
                            <Col sm={6} key={"consignee" + consignee.id+"-"+consigneeIndex+"-col1-row1-col2"}>{consignee.party_id}</Col>
                        </Row>
                        <Row key={"consignee" + consignee.id+"-"+consigneeIndex+"-col1-row2"}>
                            <Col sm={6} key={"consignee" + consignee.id+"-"+consigneeIndex+"-col1-row2-col1"}><h6>{i18n.t("Forecast.Header.DockCode")}:</h6></Col>
                            <Col sm={6} key={"consignee" + consignee.id+"-"+consigneeIndex+"-col1-row2-col2"}>{consignee.dock_code}</Col>
                        </Row>
                    </Col>
                    <Col sm={2} key={"consignee" + consignee.id+"-"+consigneeIndex+"-col2"}>
                        <Row key={"consignee" + consignee.id+"-"+consigneeIndex+"-col2-row1"}>
                            <Col sm={6} key={"consignee" + consignee.id+"-"+consigneeIndex+"-col2-row1-col1"}><h6>{i18n.t("Forecast.Header.WarehouseCode")}:</h6></Col>
                            <Col sm={6} key={"consignee" + consignee.id+"-"+consigneeIndex+"-col2-row1-col2"}>{consignee.warehouse_code}</Col>
                        </Row>
                        <Row key={"consignee" + consignee.id+"-"+consigneeIndex+"-col2-row2"}>
                            <Col sm={6} key={"consignee" + consignee.id+"-"+consigneeIndex+"-col2-row2-col1"}><h6>{i18n.t("Forecast.Header.FinalDestination")}:</h6></Col>
                            <Col sm={6} key={"consignee" + consignee.id+"-"+consigneeIndex+"-col2-row2-col2"}>{consignee.final_destination}</Col>
                        </Row>
                    </Col>
                    <Col sm={2} key={"consignee" + consignee.id+"-"+consigneeIndex+"-col3"}>
                        <Row key={"consignee" + consignee.id+"-"+consigneeIndex+"-col3-row1"}>
                            <Col sm={6} key={"consignee" + consignee.id+"-"+consigneeIndex+"-col3-row1-col1"}><h6>{i18n.t("Forecast.Header.Contact")}:</h6></Col>
                            <Col sm={6} key={"consignee" + consignee.id+"-"+consigneeIndex+"-col3-row1-col2"}>{consignee.contact}</Col>
                        </Row>
                        <Row key={"consignee" + consignee.id+"-"+consigneeIndex+"-col3-row2"}>
                            <Col sm={6} key={"consignee" + consignee.id+"-"+consigneeIndex+"-col3-row2-col1"}><h6>{i18n.t("Forecast.Header.LastUpdate")}:</h6></Col>
                            <Col sm={6} key={"consignee" + consignee.id+"-"+consigneeIndex+"-col3-row2-col2"}>{format(new Date(consignee.created),DATE_PATTERN)}</Col>
                        </Row>
                    </Col>
                </Row>
                <table className="table table-bordered align-middle bg-white">
                    <thead>
                        <tr key={"consignee" + consignee.id+"-table"} className="text-center align-top">
                            <th key={"consignee" + consignee.id +"-part"} rowSpan={2} style={{minWidth: "200px"}}>{i18n.t("Forecast.Item.PartIdentification")}</th>
                            <th key={"consignee" + consignee.id +"-ref"} rowSpan={2} style={{minWidth: "200px"}}>{i18n.t("Forecast.Item.References")}</th>
                            {weeks.map((week) => {
                                return <th key={"consignee" + consignee.id +"-week-"+week} colSpan={7}>{week}</th>
                            })}
                        </tr>
                        <tr className={"small"}>
                            {weeks.map(week => {
                                const days = getWeekDays(week);
                                return days.map(day => {
                                    const dayShort = format(day,SHORT_DATE_PATTERN);
                                    return <td className={dayShort === todayShort ? "highlightToday" : ""} key={"consignee" + consignee.id +"-date" + day}>{dayShort}</td>
                                })
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {consignee.items.map((item) => {
                        return <tr key={"consignee" + consignee.id + "-item" + item.id}>
                            <td key={"consignee" + consignee.id + "-item" + item.id + "-part"}><b>{item.specification}</b> <br/>{i18n.t("Forecast.Item.PartNo")}: {item.article_number_buyer}<br/>{i18n.t("Forecast.Item.SupplierPartNo")}: {item.article_number}</td>
                            <td key={"consignee" + consignee.id + "-item" + item.id + "-ref"}>{i18n.t("Forecast.Item.EngineeringChange")}: {item.engineering_change}<br/>{i18n.t("Forecast.Item.OrderNumber")}: {item.order_number}<br/>{i18n.t("Forecast.Item.CallOffNumber")}: {item.calloff_number}</td>
                            {weeks.map(week => {
                                const days = getWeekDays(week);
                                return days.map(day => {
                                    const filteredForecasts = item.forecasts?.filter(fc => format(new Date(fc.delivery_start_date),DATE_PATTERN) === format(day, DATE_PATTERN))
                                    let classN = "horizontal-value " + (filteredForecasts && filteredForecasts[0]?.commitment_delivery_code ? "forecast-code-" + filteredForecasts[0]?.commitment_delivery_code : "");
                                    const dayShort = format(day,SHORT_DATE_PATTERN);
                                    if (dayShort === todayShort) {
                                        classN+=" highlightToday";
                                    }
                                    return <td
                                        className={classN}
                                        key={"consignee" + consignee.id + "-item" + item.id + "-date" + day}>
                                        {(filteredForecasts && filteredForecasts[0]?.quantity) ?? 0}</td>
                                })
                            })}
                        </tr>
                        })}
                    </tbody>
                </table>
            </div>
        })}
    </div>
}
export default Forecast;
